import commonUtils from "../../../common/commonUtils";

const state = {
     exampleData:{
         "maxIf":98.4,
         "total":496,
         "list": [
             {
                 "id": 251631,
                 "userId": "731924809596829696",
                 "nickName": "如人饮水",
                 "processId": null,
                 "coverUrl": "1552271013.jpg",
                 "name": "LUNG CANCER",
                 "ifs": 4.5,
                 "articleCount": 2832,
                 "edition": "SCIE",
                 "partCas": 2,
                 "partJcr": 1,
                 "country": "荷兰",
                 "chinaPercent": 0.0965,
                 "selfCitationPercent": 0.0204,
                 "issueIntervalNum": 12,
                 "link": "https://www.journals.elsevier.com/lung-cancer",
                 "sciRiskRank": "低",
                 "ifs2021": 4.5,
                 "ifs2020": 5.3,
                 "ifs2019": 6.081,
                 "ifs2018": 5.705,
                 "ifs2017": 4.702,
                 "isNew": null,
                 "yearAvgNum": 242,
                 "yearAvgChinaNum": 56,
                 "yearAvgJson": "[{\"chinaPubNum\":16,\"pubNum\":223,\"year\":2010},{\"chinaPubNum\":26,\"pubNum\":260,\"year\":2011},{\"chinaPubNum\":25,\"pubNum\":226,\"year\":2012},{\"chinaPubNum\":22,\"pubNum\":277,\"year\":2013},{\"chinaPubNum\":36,\"pubNum\":252,\"year\":2014},{\"chinaPubNum\":30,\"pubNum\":246,\"year\":2015},{\"chinaPubNum\":25,\"pubNum\":260,\"year\":2016},{\"chinaPubNum\":33,\"pubNum\":347,\"year\":2017},{\"chinaPubNum\":58,\"pubNum\":352,\"year\":2018},{\"chinaPubNum\":68,\"pubNum\":394,\"year\":2019},{\"chinaPubNum\":86,\"pubNum\":355,\"year\":2020},{\"chinaPubNum\":67,\"pubNum\":323,\"year\":2021},{\"chinaPubNum\":49,\"pubNum\":236,\"year\":2022},{\"chinaPubNum\":50,\"pubNum\":232,\"year\":2023},{\"chinaPubNum\":70,\"pubNum\":260,\"year\":2024},{\"chinaPubNum\":16,\"pubNum\":94,\"year\":2025}]",
                 "commentCount": 2,
                 "issnOnline": null,
                 "issnPrint": null,
                 "publicationFee": null,
                 "openAccess": "否",
                 "publicationTime": null,
                 "publisher": "ELSEVIER IRELAND LTD",
                 "linkOfficialWebsite": "https://www.journals.elsevier.com/lung-cancer"
             },
             {
                 "id": 252038,
                 "userId": "731924809596829696",
                 "nickName": "如人饮水",
                 "processId": null,
                 "coverUrl": "Journal of Thoracic Oncology.jpg",
                 "name": "JOURNAL OF THORACIC ONCOLOGY",
                 "ifs": 21.0,
                 "articleCount": 2283,
                 "edition": "SCIE",
                 "partCas": 1,
                 "partJcr": 1,
                 "country": "美国",
                 "chinaPercent": 0.1469,
                 "selfCitationPercent": 0.0298,
                 "issueIntervalNum": 12,
                 "link": "https://www.journals.elsevier.com/journal-of-thoracic-oncology",
                 "sciRiskRank": "低",
                 "ifs2021": 21.0,
                 "ifs2020": 20.4,
                 "ifs2019": 20.121,
                 "ifs2018": 15.609,
                 "ifs2017": 13.357,
                 "isNew": null,
                 "yearAvgNum": 240,
                 "yearAvgChinaNum": 51,
                 "yearAvgJson": "[{\"chinaPubNum\":16,\"pubNum\":453,\"year\":2010},{\"chinaPubNum\":14,\"pubNum\":399,\"year\":2011},{\"chinaPubNum\":12,\"pubNum\":304,\"year\":2012},{\"chinaPubNum\":7,\"pubNum\":246,\"year\":2013},{\"chinaPubNum\":29,\"pubNum\":393,\"year\":2014},{\"chinaPubNum\":21,\"pubNum\":267,\"year\":2015},{\"chinaPubNum\":44,\"pubNum\":391,\"year\":2016},{\"chinaPubNum\":39,\"pubNum\":295,\"year\":2017},{\"chinaPubNum\":50,\"pubNum\":367,\"year\":2018},{\"chinaPubNum\":88,\"pubNum\":372,\"year\":2019},{\"chinaPubNum\":71,\"pubNum\":305,\"year\":2020},{\"chinaPubNum\":46,\"pubNum\":287,\"year\":2021},{\"chinaPubNum\":37,\"pubNum\":198,\"year\":2022},{\"chinaPubNum\":60,\"pubNum\":282,\"year\":2023},{\"chinaPubNum\":57,\"pubNum\":241,\"year\":2024},{\"chinaPubNum\":23,\"pubNum\":81,\"year\":2025}]",
                 "commentCount": 6,
                 "issnOnline": null,
                 "issnPrint": null,
                 "publicationFee": null,
                 "openAccess": "否",
                 "publicationTime": null,
                 "publisher": "ELSEVIER SCIENCE INC",
                 "linkOfficialWebsite": "https://www.journals.elsevier.com/journal-of-thoracic-oncology"
             },
             {
                 "id": 251923,
                 "userId": "731924809596829696",
                 "nickName": "如人饮水",
                 "processId": null,
                 "coverUrl": "up_img_a_5465.png",
                 "name": "JOURNAL OF THORACIC DISEASE",
                 "ifs": 2.1,
                 "articleCount": 2045,
                 "edition": "SCIE",
                 "partCas": 4,
                 "partJcr": 3,
                 "country": "中国",
                 "chinaPercent": 0.3672,
                 "selfCitationPercent": 0.0407,
                 "issueIntervalNum": 12,
                 "link": "https://auth.amegroups.cn/sign-in?service=https%3A%2F%2Fjtd.amegroups.org%2Flogin%3Fsource%3D%252Fauthor%252Fsubmit&lang=en-US",
                 "sciRiskRank": "低",
                 "ifs2021": 2.1,
                 "ifs2020": 2.5,
                 "ifs2019": 3.005,
                 "ifs2018": 2.895,
                 "ifs2017": 2.046,
                 "isNew": null,
                 "yearAvgNum": 687,
                 "yearAvgChinaNum": 320,
                 "yearAvgJson": "[{\"chinaPubNum\":0,\"pubNum\":0,\"year\":2010},{\"chinaPubNum\":0,\"pubNum\":0,\"year\":2011},{\"chinaPubNum\":39,\"pubNum\":240,\"year\":2012},{\"chinaPubNum\":100,\"pubNum\":307,\"year\":2013},{\"chinaPubNum\":184,\"pubNum\":446,\"year\":2014},{\"chinaPubNum\":202,\"pubNum\":496,\"year\":2015},{\"chinaPubNum\":278,\"pubNum\":975,\"year\":2016},{\"chinaPubNum\":321,\"pubNum\":1453,\"year\":2017},{\"chinaPubNum\":404,\"pubNum\":1704,\"year\":2018},{\"chinaPubNum\":311,\"pubNum\":1340,\"year\":2019},{\"chinaPubNum\":350,\"pubNum\":1031,\"year\":2020},{\"chinaPubNum\":283,\"pubNum\":695,\"year\":2021},{\"chinaPubNum\":234,\"pubNum\":546,\"year\":2022},{\"chinaPubNum\":296,\"pubNum\":689,\"year\":2023},{\"chinaPubNum\":431,\"pubNum\":827,\"year\":2024},{\"chinaPubNum\":100,\"pubNum\":168,\"year\":2025}]",
                 "commentCount": 9,
                 "issnOnline": null,
                 "issnPrint": null,
                 "publicationFee": null,
                 "openAccess": "否",
                 "publicationTime": null,
                 "publisher": "AME PUBL CO",
                 "linkOfficialWebsite": "http://jtd.amegroups.com/about"
             },
             {
                 "id": 251789,
                 "userId": "731924809596829696",
                 "nickName": "如人饮水",
                 "processId": null,
                 "coverUrl": "Frontiers in Oncology.jpg",
                 "name": "FRONTIERS IN ONCOLOGY",
                 "ifs": 3.5,
                 "articleCount": 1919,
                 "edition": "SCIE",
                 "partCas": 3,
                 "partJcr": 2,
                 "country": "瑞士",
                 "chinaPercent": 0.5088,
                 "selfCitationPercent": 0.0497,
                 "issueIntervalNum": 1,
                 "link": "https://www.frontiersin.org/submission/submit?domainid=2&fieldid=57&specialtyid=0&entitytype=2&entityid=451",
                 "sciRiskRank": "低",
                 "ifs2021": 3.5,
                 "ifs2020": 4.7,
                 "ifs2019": 5.738,
                 "ifs2018": 6.244,
                 "ifs2017": 4.848,
                 "isNew": null,
                 "yearAvgNum": 5473,
                 "yearAvgChinaNum": 3295,
                 "yearAvgJson": "[{\"chinaPubNum\":0,\"pubNum\":0,\"year\":2010},{\"chinaPubNum\":1,\"pubNum\":1,\"year\":2011},{\"chinaPubNum\":2,\"pubNum\":251,\"year\":2012},{\"chinaPubNum\":0,\"pubNum\":322,\"year\":2013},{\"chinaPubNum\":4,\"pubNum\":369,\"year\":2014},{\"chinaPubNum\":1,\"pubNum\":312,\"year\":2015},{\"chinaPubNum\":4,\"pubNum\":287,\"year\":2016},{\"chinaPubNum\":5,\"pubNum\":307,\"year\":2017},{\"chinaPubNum\":65,\"pubNum\":621,\"year\":2018},{\"chinaPubNum\":566,\"pubNum\":1445,\"year\":2019},{\"chinaPubNum\":1587,\"pubNum\":2935,\"year\":2020},{\"chinaPubNum\":3779,\"pubNum\":5809,\"year\":2021},{\"chinaPubNum\":4901,\"pubNum\":7626,\"year\":2022},{\"chinaPubNum\":2876,\"pubNum\":5093,\"year\":2023},{\"chinaPubNum\":2110,\"pubNum\":3701,\"year\":2024},{\"chinaPubNum\":602,\"pubNum\":868,\"year\":2025}]",
                 "commentCount": 11,
                 "issnOnline": "2234-943X",
                 "issnPrint": null,
                 "publicationFee": "3295USD;",
                 "openAccess": "是",
                 "publicationTime": 14,
                 "publisher": "Frontiers Media S.A.",
                 "linkOfficialWebsite": "https://www.frontiersin.org/journals/oncology"
             },
             {
                 "id": 251850,
                 "userId": "731924809596829696",
                 "nickName": "如人饮水",
                 "processId": null,
                 "coverUrl": "11554.png",
                 "name": "THORACIC CANCER",
                 "ifs": 2.3,
                 "articleCount": 1673,
                 "edition": "SCIE",
                 "partCas": 4,
                 "partJcr": 2,
                 "country": "澳大利亚",
                 "chinaPercent": 0.4908,
                 "selfCitationPercent": 0.0267,
                 "issueIntervalNum": 24,
                 "link": "https://onlinelibrary.wiley.com/journal/17597714",
                 "sciRiskRank": "低",
                 "ifs2021": 2.3,
                 "ifs2020": 2.9,
                 "ifs2019": 3.223,
                 "ifs2018": 3.5,
                 "ifs2017": 2.61,
                 "isNew": null,
                 "yearAvgNum": 369,
                 "yearAvgChinaNum": 190,
                 "yearAvgJson": "[{\"chinaPubNum\":11,\"pubNum\":29,\"year\":2010},{\"chinaPubNum\":12,\"pubNum\":39,\"year\":2011},{\"chinaPubNum\":0,\"pubNum\":0,\"year\":2012},{\"chinaPubNum\":0,\"pubNum\":0,\"year\":2013},{\"chinaPubNum\":0,\"pubNum\":0,\"year\":2014},{\"chinaPubNum\":92,\"pubNum\":126,\"year\":2015},{\"chinaPubNum\":148,\"pubNum\":210,\"year\":2016},{\"chinaPubNum\":172,\"pubNum\":287,\"year\":2017},{\"chinaPubNum\":179,\"pubNum\":257,\"year\":2018},{\"chinaPubNum\":194,\"pubNum\":321,\"year\":2019},{\"chinaPubNum\":300,\"pubNum\":481,\"year\":2020},{\"chinaPubNum\":243,\"pubNum\":471,\"year\":2021},{\"chinaPubNum\":224,\"pubNum\":448,\"year\":2022},{\"chinaPubNum\":197,\"pubNum\":384,\"year\":2023},{\"chinaPubNum\":150,\"pubNum\":277,\"year\":2024},{\"chinaPubNum\":37,\"pubNum\":62,\"year\":2025}]",
                 "commentCount": 2,
                 "issnOnline": "1759-7714",
                 "issnPrint": "1759-7706",
                 "publicationFee": "2750USD;1850GBP;2500EUR;",
                 "openAccess": "是",
                 "publicationTime": 41,
                 "publisher": "Wiley",
                 "linkOfficialWebsite": "https://onlinelibrary.wiley.com/journal/17597714"
             },
             {
                 "id": 251688,
                 "userId": "731924809596829696",
                 "nickName": "如人饮水",
                 "processId": null,
                 "coverUrl": "190627_img_tw_250250547.jpg",
                 "name": "TRANSLATIONAL LUNG CANCER RESEARCH",
                 "ifs": 4.0,
                 "articleCount": 1568,
                 "edition": "SCIE",
                 "partCas": 3,
                 "partJcr": 1,
                 "country": "中国大陆",
                 "chinaPercent": 0.2378,
                 "selfCitationPercent": 0.0405,
                 "issueIntervalNum": 6,
                 "link": "https://auth.amegroups.cn/sign-in?service=https%3A%2F%2Ftlcr.amegroups.org%2Flogin%3Fsource%3D%252Fauthor%252Fsubmit&lang=en-US",
                 "sciRiskRank": "低",
                 "ifs2021": 4.0,
                 "ifs2020": 4.0,
                 "ifs2019": 4.726,
                 "ifs2018": 6.498,
                 "ifs2017": 5.132,
                 "isNew": null,
                 "yearAvgNum": 268,
                 "yearAvgChinaNum": 128,
                 "yearAvgJson": "[{\"chinaPubNum\":0,\"pubNum\":0,\"year\":2010},{\"chinaPubNum\":0,\"pubNum\":0,\"year\":2011},{\"chinaPubNum\":0,\"pubNum\":0,\"year\":2012},{\"chinaPubNum\":0,\"pubNum\":0,\"year\":2013},{\"chinaPubNum\":0,\"pubNum\":0,\"year\":2014},{\"chinaPubNum\":38,\"pubNum\":287,\"year\":2015},{\"chinaPubNum\":9,\"pubNum\":115,\"year\":2016},{\"chinaPubNum\":11,\"pubNum\":99,\"year\":2017},{\"chinaPubNum\":11,\"pubNum\":186,\"year\":2018},{\"chinaPubNum\":34,\"pubNum\":144,\"year\":2019},{\"chinaPubNum\":131,\"pubNum\":342,\"year\":2020},{\"chinaPubNum\":152,\"pubNum\":375,\"year\":2021},{\"chinaPubNum\":147,\"pubNum\":261,\"year\":2022},{\"chinaPubNum\":75,\"pubNum\":248,\"year\":2023},{\"chinaPubNum\":162,\"pubNum\":297,\"year\":2024},{\"chinaPubNum\":61,\"pubNum\":102,\"year\":2025}]",
                 "commentCount": 2,
                 "issnOnline": null,
                 "issnPrint": null,
                 "publicationFee": null,
                 "openAccess": "是",
                 "publicationTime": null,
                 "publisher": "AME PUBL CO",
                 "linkOfficialWebsite": "https://tlcr.amegroups.com/"
             },
             {
                 "id": 251890,
                 "userId": "731924809596829696",
                 "nickName": "如人饮水",
                 "processId": null,
                 "coverUrl": "15816341713379cf6e6756f.jpg",
                 "name": "CANCERS",
                 "ifs": 4.5,
                 "articleCount": 1552,
                 "edition": "SCIE",
                 "partCas": 3,
                 "partJcr": 1,
                 "country": "瑞士",
                 "chinaPercent": 0.054,
                 "selfCitationPercent": 0.0893,
                 "issueIntervalNum": 24,
                 "link": "https://www.mdpi.com/journal/cancers",
                 "sciRiskRank": "低",
                 "ifs2021": 4.5,
                 "ifs2020": 5.2,
                 "ifs2019": 6.575,
                 "ifs2018": 6.639,
                 "ifs2017": 6.126,
                 "isNew": null,
                 "yearAvgNum": 5429,
                 "yearAvgChinaNum": 395,
                 "yearAvgJson": "[{\"chinaPubNum\":0,\"pubNum\":2,\"year\":2010},{\"chinaPubNum\":0,\"pubNum\":22,\"year\":2011},{\"chinaPubNum\":0,\"pubNum\":7,\"year\":2012},{\"chinaPubNum\":5,\"pubNum\":477,\"year\":2013},{\"chinaPubNum\":0,\"pubNum\":118,\"year\":2014},{\"chinaPubNum\":0,\"pubNum\":133,\"year\":2015},{\"chinaPubNum\":3,\"pubNum\":111,\"year\":2016},{\"chinaPubNum\":4,\"pubNum\":174,\"year\":2017},{\"chinaPubNum\":24,\"pubNum\":550,\"year\":2018},{\"chinaPubNum\":89,\"pubNum\":2077,\"year\":2019},{\"chinaPubNum\":140,\"pubNum\":3860,\"year\":2020},{\"chinaPubNum\":236,\"pubNum\":6336,\"year\":2021},{\"chinaPubNum\":592,\"pubNum\":6267,\"year\":2022},{\"chinaPubNum\":450,\"pubNum\":5899,\"year\":2023},{\"chinaPubNum\":143,\"pubNum\":4122,\"year\":2024},{\"chinaPubNum\":27,\"pubNum\":1073,\"year\":2025}]",
                 "commentCount": 6,
                 "issnOnline": "2072-6694",
                 "issnPrint": "",
                 "publicationFee": "2900CHF;",
                 "openAccess": "是",
                 "publicationTime": 6,
                 "publisher": "MDPI AG",
                 "linkOfficialWebsite": "https://www.mdpi.com/journal/cancers"
             },
             {
                 "id": 251755,
                 "userId": "731924809596829696",
                 "nickName": "如人饮水",
                 "processId": null,
                 "coverUrl": "PLoS One.png",
                 "name": "PLOS ONE",
                 "ifs": 2.9,
                 "articleCount": 1448,
                 "edition": "SCIE",
                 "partCas": 3,
                 "partJcr": 1,
                 "country": "美国",
                 "chinaPercent": 0.0896,
                 "selfCitationPercent": 0.0223,
                 "issueIntervalNum": 0,
                 "link": "https://journals.plos.org/plosone/",
                 "sciRiskRank": "低",
                 "ifs2021": 2.9,
                 "ifs2020": 3.7,
                 "ifs2019": 3.752,
                 "ifs2018": 3.24,
                 "ifs2017": 2.74,
                 "isNew": null,
                 "yearAvgNum": 16211,
                 "yearAvgChinaNum": 2591,
                 "yearAvgJson": "[{\"chinaPubNum\":310,\"pubNum\":6392,\"year\":2010},{\"chinaPubNum\":1288,\"pubNum\":13847,\"year\":2011},{\"chinaPubNum\":2910,\"pubNum\":22685,\"year\":2012},{\"chinaPubNum\":5302,\"pubNum\":31937,\"year\":2013},{\"chinaPubNum\":5906,\"pubNum\":30721,\"year\":2014},{\"chinaPubNum\":5068,\"pubNum\":29904,\"year\":2015},{\"chinaPubNum\":3481,\"pubNum\":23076,\"year\":2016},{\"chinaPubNum\":3063,\"pubNum\":21189,\"year\":2017},{\"chinaPubNum\":1893,\"pubNum\":18705,\"year\":2018},{\"chinaPubNum\":1449,\"pubNum\":16303,\"year\":2019},{\"chinaPubNum\":1669,\"pubNum\":16768,\"year\":2020},{\"chinaPubNum\":1709,\"pubNum\":16624,\"year\":2021},{\"chinaPubNum\":1846,\"pubNum\":16068,\"year\":2022},{\"chinaPubNum\":2278,\"pubNum\":14971,\"year\":2023},{\"chinaPubNum\":3651,\"pubNum\":17595,\"year\":2024},{\"chinaPubNum\":1020,\"pubNum\":4249,\"year\":2025}]",
                 "commentCount": 45,
                 "issnOnline": null,
                 "issnPrint": null,
                 "publicationFee": null,
                 "openAccess": "是",
                 "publicationTime": null,
                 "publisher": "PUBLIC LIBRARY SCIENCE",
                 "linkOfficialWebsite": "https://journals.plos.org/plosone/"
             },
             {
                 "id": 251854,
                 "userId": "731924809596829696",
                 "nickName": "如人饮水",
                 "processId": null,
                 "coverUrl": "Clinical Lung Cancer.jpg",
                 "name": "CLINICAL LUNG CANCER",
                 "ifs": 3.3,
                 "articleCount": 1407,
                 "edition": "SCIE",
                 "partCas": 3,
                 "partJcr": 2,
                 "country": "美国",
                 "chinaPercent": 0.0938,
                 "selfCitationPercent": 0.0164,
                 "issueIntervalNum": 6,
                 "link": "https://www.journals.elsevier.com/clinical-lung-cancer",
                 "sciRiskRank": "低",
                 "ifs2021": 3.3,
                 "ifs2020": 3.6,
                 "ifs2019": 4.84,
                 "ifs2018": 4.785,
                 "ifs2017": 3.55,
                 "isNew": null,
                 "yearAvgNum": 142,
                 "yearAvgChinaNum": 18,
                 "yearAvgJson": "[{\"chinaPubNum\":5,\"pubNum\":55,\"year\":2010},{\"chinaPubNum\":15,\"pubNum\":104,\"year\":2011},{\"chinaPubNum\":5,\"pubNum\":64,\"year\":2012},{\"chinaPubNum\":9,\"pubNum\":84,\"year\":2013},{\"chinaPubNum\":6,\"pubNum\":85,\"year\":2014},{\"chinaPubNum\":14,\"pubNum\":96,\"year\":2015},{\"chinaPubNum\":11,\"pubNum\":127,\"year\":2016},{\"chinaPubNum\":21,\"pubNum\":168,\"year\":2017},{\"chinaPubNum\":26,\"pubNum\":164,\"year\":2018},{\"chinaPubNum\":24,\"pubNum\":157,\"year\":2019},{\"chinaPubNum\":31,\"pubNum\":213,\"year\":2020},{\"chinaPubNum\":22,\"pubNum\":167,\"year\":2021},{\"chinaPubNum\":16,\"pubNum\":126,\"year\":2022},{\"chinaPubNum\":16,\"pubNum\":148,\"year\":2023},{\"chinaPubNum\":23,\"pubNum\":152,\"year\":2024},{\"chinaPubNum\":6,\"pubNum\":41,\"year\":2025}]",
                 "commentCount": 1,
                 "issnOnline": null,
                 "issnPrint": null,
                 "publicationFee": null,
                 "openAccess": "否",
                 "publicationTime": null,
                 "publisher": "CIG MEDIA GROUP, LP",
                 "linkOfficialWebsite": "https://www.journals.elsevier.com/clinical-lung-cancer"
             },
             {
                 "id": 251787,
                 "userId": "731924809596829696",
                 "nickName": "如人饮水",
                 "processId": null,
                 "coverUrl": "1581695194280cacdf35d8a.jpg",
                 "name": "ONCOLOGY LETTERS",
                 "ifs": 2.5,
                 "articleCount": 1156,
                 "edition": "SCIE",
                 "partCas": 4,
                 "partJcr": 3,
                 "country": "希腊",
                 "chinaPercent": 0.6129,
                 "selfCitationPercent": 0.0083,
                 "issueIntervalNum": 12,
                 "link": "https://www.spandidos-publications.com/login",
                 "sciRiskRank": "高",
                 "ifs2021": 2.5,
                 "ifs2020": 2.9,
                 "ifs2019": 3.111,
                 "ifs2018": 2.967,
                 "ifs2017": 2.311,
                 "isNew": null,
                 "yearAvgNum": 564,
                 "yearAvgChinaNum": 327,
                 "yearAvgJson": "[{\"chinaPubNum\":0,\"pubNum\":1,\"year\":2010},{\"chinaPubNum\":0,\"pubNum\":6,\"year\":2011},{\"chinaPubNum\":236,\"pubNum\":983,\"year\":2012},{\"chinaPubNum\":372,\"pubNum\":697,\"year\":2013},{\"chinaPubNum\":656,\"pubNum\":991,\"year\":2014},{\"chinaPubNum\":666,\"pubNum\":918,\"year\":2015},{\"chinaPubNum\":1066,\"pubNum\":1589,\"year\":2016},{\"chinaPubNum\":1270,\"pubNum\":1894,\"year\":2017},{\"chinaPubNum\":1940,\"pubNum\":2428,\"year\":2018},{\"chinaPubNum\":1167,\"pubNum\":1517,\"year\":2019},{\"chinaPubNum\":998,\"pubNum\":1306,\"year\":2020},{\"chinaPubNum\":492,\"pubNum\":759,\"year\":2021},{\"chinaPubNum\":244,\"pubNum\":454,\"year\":2022},{\"chinaPubNum\":336,\"pubNum\":565,\"year\":2023},{\"chinaPubNum\":403,\"pubNum\":673,\"year\":2024},{\"chinaPubNum\":80,\"pubNum\":122,\"year\":2025}]",
                 "commentCount": 18,
                 "issnOnline": null,
                 "issnPrint": null,
                 "publicationFee": null,
                 "openAccess": "否",
                 "publicationTime": null,
                 "publisher": "SPANDIDOS PUBL LTD",
                 "linkOfficialWebsite": "https://www.spandidos-publications.com/ol"
             }
         ]
     }
}

// getters
const getters = {

}

// actions
const actions = {



}

// mutations
const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
